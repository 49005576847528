var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "ul",
      _vm._l(_vm.links, function(link, index) {
        return _c(
          "li",
          { key: index },
          [
            _c("router-link", { attrs: { to: link.to } }, [
              _vm._v(_vm._s(link.name))
            ])
          ],
          1
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("main", [_c("router-view")], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }