import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

new Vue({
    el: '#app',
    router,
    components: { App },
    template: '<App/>'
});



if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker-cache.js').
        then(function (registration) {
            // Registration was successful``
            console.log('ServiceWorker registration successful with scope: ');
        }).catch(function (err) {
            // registration failed :(
            console.log('ServiceWorker registration failed: ');
        });
}

